.stickyElx {
  transition: all 0.5s;
  z-index: 1;
}

.fadeIn {
  -webkit-animation: fadeIn 200ms ease-in-out;
  animation: fadeIn 200ms ease-in-out;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}